import React, {useEffect} from "react";
import {Link} from "react-router-dom"
import "./Header.css"
import logo from "../../assets/logo.jpg"
import {getActiveLanguage, getUser} from "../redux/getters";
import {useDispatch, useSelector} from "react-redux";
import Menu from "./Menu";
import {logoutUser, setActiveLang, setUserAction} from "../redux/userActions";
import {getUserFromLocalStorage} from "../../assets/helpers";

const Header = () => {
    const user = useSelector(getUser)
    const dispatch = useDispatch()
    useEffect(() => {
        if (!user) {
            dispatch(setUserAction(getUserFromLocalStorage()))
        }
    }, [dispatch, user]);
    const language = useSelector(getActiveLanguage)
    const setLanguage = (lang) => dispatch(setActiveLang(lang))
    const onLogout = () => dispatch(logoutUser())
    return (
        <nav className="navbar navbar-light navbar-expand-lg bg-light py-3 mb-5">
            <div className="container">
                <Link to={"/"} className={"navbar-brand"}>
                    <img src={logo} className={"logo w-100"} alt="logoType"/>
                </Link>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbar"
                        aria-controls="navbar" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"/>
                </button>
                <div className="collapse navbar-collapse" id="navbar">
                    <Menu setLang={setLanguage} user={user} language={language} onLogout={onLogout}/>
                </div>
            </div>
        </nav>
    );
};

export default Header;
