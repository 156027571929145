import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import useForm from "../hooks/useForm";
import {getAxiosCountries, loginUser} from "../components/redux/userActions";
import UserLoginForm from "../components/UserLogin/UserLoginForm";
import {getActiveLanguage, getStateCountries} from "../components/redux/getters";

const UserPage = () => {
    const userValues = useForm({
        firstName: "",
        lastName: "",
        email: "",
        countryId: ""
    })
    const language = useSelector(getActiveLanguage)
    const dispatch = useDispatch()
    const countries = useSelector(getStateCountries)
    const onLogin = (e) => {
        e.preventDefault()
        dispatch(loginUser(userValues.values, countries.find(c => userValues.values.countryId === c._id)))
    }
    useEffect(() => {
        dispatch(getAxiosCountries())
    }, [dispatch])
    return (
        <div className={"container auth-container"}>
            <UserLoginForm countries={countries} lang={language} onSubmit={onLogin} userValues={userValues}/>
        </div>
    );
};

export default UserPage;
