import React, {useEffect} from "react";
import languages from "../../assets/languages";

const UserLoginForm = ({userValues, onSubmit, lang, countries}) => {
    useEffect(() => {
        userValues.clearForm()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <form onSubmit={onSubmit}>
            <label htmlFor="firstName" className="form-label">{languages[lang].auth.name}</label>
            <input {...userValues.binder.firstName}
                   required
                   type="text" className="form-control mb-1" placeholder={languages[lang].auth.namePlace}
                   id="firstName"/>
            <label htmlFor="lastName" className="form-label">{languages[lang].auth.surname}</label>
            <input {...userValues.binder.lastName}
                   required
                   type="text" placeholder={languages[lang].auth.surnamePlace} className="form-control mb-1"
                   id="lastName"/>
            <label htmlFor="exampleInputEmail1" className="form-label">{languages[lang].auth.email}</label>
            <input {...userValues.binder.email}
                   placeholder={languages[lang].auth.emailPlace}
                   required
                   type="email" className="form-control mb-3" id="exampleInputEmail1"
                   aria-describedby="emailHelp"/>
            <select {...userValues.binder.countryId} required className="form-select mb-3" aria-label="Default select example">
                <option>{languages[lang].auth.countryPlace}</option>
                {
                    countries.map(c => {
                        return <option key={c._id} value={c._id}>{c.name}</option>
                    })
                }
            </select>
            <button type="submit" className="btn btn-lg px-3 bg-dark-green text-light border-dark">{languages[lang].auth.loginBtn}</button>
        </form>
    );

};

export default UserLoginForm;
