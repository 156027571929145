import React from "react";
import {useSelector} from "react-redux";
import {getUser} from "../components/redux/getters";
import ProtectedRoute from "../components/ProtectedRoute/ProtectedRoute";
import UserPage from "./UserPage";
import {Route, Redirect, Switch} from "react-router-dom";
import MainPage from "../components/MainPage/MainPage";
import QuestionsInfoPage from "../components/QuestionsPage/QuestionsInfoPage";
import QuestionPage from "../components/QuestionPage/QuestionPage";
import TestResultPage from "../components/TestResultPage/TestResultPage";
import AdminPage from "./AdminPage";
import FourTendentions from "./FourTendentions";

const Routes = () => {
    const user = useSelector(getUser)
    return (
        <Switch>
            <Route exact path={"/"} component={MainPage}/>
            <ProtectedRoute isAllowed={!user}
                            redirectTo="/"
                            component={UserPage}
                            exact path={"/user/login"}/>
            <Route exact path={"/admin"} component={AdminPage}/>
            <ProtectedRoute isAllowed={user}
                            redirectTo="/user/login"
                            component={QuestionsInfoPage}
                            exact path={"/questions"}/>
            <Route component={FourTendentions}
                   exact
                   path={"/fourtendentions"}/>
            <ProtectedRoute isAllowed={user}
                            redirectTo={"/"}
                            component={QuestionPage}
                            exact
                            path={"/questions/:questionId"}/>
            <ProtectedRoute isAllowed={user}
                            redirectTo={"/user/login"}
                            component={TestResultPage}
                            exact
                            path={"/result"}/>
            <Redirect to="/"/>
        </Switch>
    );

};

export default Routes;
