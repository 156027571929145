import React from 'react';
import texts from "../../assets/languages";
import {ENGLISH, GERMANY, GRUZIAN, KENIROANDA, MONGOL, RUS, SPANISH} from "../redux/userTypes";
import germanFlag from "../../assets/german.jpg";
import kyzrgyzFlag from "../../assets/kyrgyz.png";
import gruzianFlag from "../../assets/gruzian.png";
import spainFlag from "../../assets/spain-flag.png";
import mongolianFlag from "../../assets/mongolian.png";
import flagEn from "../../assets/flagEn.png";
import flagRwanda from "../../assets/flagRwanda.svg";
import {NavLink} from "react-router-dom";

const Menu = ({setLang, language, user, onLogout}) => {
  return (
    <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
      <li className="nav-item dropdown">
        <a href={"#"}
           className="nav-link d-inline-flex align-items-center dropdown-toggle fw-bolder"
           id="navbarDropdown" role="button"
           data-bs-toggle="dropdown"
           aria-expanded="false">
          <div className={"flag-parent"}>
            <img className={"w-100"} src={germanFlag} alt="flaggerman"/>
          </div>
          <div className={"flag-parent"}>
            <img src={kyzrgyzFlag} className={"w-100"} alt="flagrus"/>
          </div>
          <div className={"flag-parent"}>
            <img src={gruzianFlag} className={"w-100"} alt="flagrus"/>
          </div>
          <div className={"flag-parent"}>
            <img src={spainFlag} className={"w-100"} alt="flagrus"/>
          </div>
          <div className={"flag-parent"}>
            <img src={mongolianFlag} style={{height: '19px'}} className={"w-100"} alt="flagrus"/>
          </div>
          <div className={"flag-parent"}>
            <img src={flagRwanda} style={{height: '19px'}} className={"w-100"} alt="flagrus"/>
          </div>
          <div className={"flag-parent"}>
            <img src={flagEn} style={{height: '19px'}} className={"w-100"} alt="flagrus"/>
          </div>
          {texts[language].header.changeLangTxt}
        </a>
        <ul className="dropdown-menu w-100" aria-labelledby="navbarDropdown">
          <li className={language === GERMANY ? "bg-gray" : ""}>
            <a onClick={setLang.bind(null, GERMANY)}
               className="
                           d-flex align-items-center justify-content-evenly
                           dropdown-item"
               href="#">
              <span style={{
                width: '100px',
              }}>
              Deutsch
              </span>
              <div className={"flag-parent"}>
                <img className={"w-100"} src={germanFlag} alt="flaggerman"/>
              </div>
            </a>
          </li>
          <li className={language === RUS ? "bg-gray" : ""}>
            <a onClick={setLang.bind(null, RUS)}
               className="
                           d-flex align-items-center justify-content-evenly
                           dropdown-item"
               href="#">
              <span style={{
                width: '100px',
              }}>
              Russian
              </span>
              <div className={"flag-parent"}>
                <img src={kyzrgyzFlag} className={"w-100"} alt="flagrus"/>
              </div>
            </a>
          </li>
          <li className={language === GRUZIAN ? "bg-gray" : ""}>
            <a onClick={setLang.bind(null, GRUZIAN)}
               className="
                           d-flex align-items-center justify-content-evenly
                           dropdown-item"
               href="#">
              <span style={{
                width: '100px',
              }}>
              Georgia

              </span>
              <div className={"flag-parent"}>
                <img src={gruzianFlag} className={"w-100"} alt="flagrus"/>
              </div>
            </a>
          </li>
          <li className={language === SPANISH ? "bg-gray" : ""}>
            <a onClick={setLang.bind(null, SPANISH)}
               className="
                           d-flex align-items-center justify-content-evenly
                           dropdown-item"
               href="#">
              <span style={{
                width: '100px',
              }}>
              Spanish

              </span>
              <div className={"flag-parent"}>
                <img src={spainFlag} className={"w-100"} alt="flagrus"/>
              </div>
            </a>
          </li>
          <li className={language === MONGOL ? "bg-gray" : ""}>
            <a onClick={setLang.bind(null, MONGOL)}
               className="
                           d-flex align-items-center justify-content-evenly
                           dropdown-item"
               href="#">
              <span style={{
                width: '100px',
              }}>
              Mongolian

              </span>
              <div className={"flag-parent"}>
                <img src={mongolianFlag} className={"w-100"} alt="flagrus"/>
              </div>
            </a>
          </li>
          <li className={language === ENGLISH ? "bg-gray" : ""}>
            <a onClick={setLang.bind(null, ENGLISH)}
               className="
                           d-flex align-items-center justify-content-evenly
                           dropdown-item"
               href="#">
              <span style={{
                width: '100px',
              }}>
              English

              </span>
              <div className={"flag-parent"}>
                <img src={flagEn} className={"w-100"} alt="flagrus"/>
              </div>
            </a>
          </li>
          <li className={language === KENIROANDA ? "bg-gray" : ""}>
            <a onClick={setLang.bind(null, KENIROANDA)}
               className="
                           d-flex align-items-center justify-content-evenly
                           dropdown-item"
               href="#">
              <span style={{
                width: '100px',
              }}>
              Rwanda KeRW

              </span>
              <div className={"flag-parent"}>
                <img src={flagRwanda} className={"w-100"} alt="flagrus"/>
              </div>
            </a>
          </li>
        </ul>
      </li>
      <li className="nav-item">
        {user ? <button className={"btn nav-link border-0"}
                        onClick={onLogout}>{texts[language].header.logoutBtn}</button> :
          <NavLink to={"/user/login"}
                   className="nav-link"
                   aria-current="page"
                   href="#">{texts[language].header.loginBtn}
          </NavLink>}
      </li>
    </ul>
  );
};

export default Menu;
