import React, {useState} from 'react';
import languages from "../../assets/languages";
import {serverURL} from "../redux/store";
import {getAxiosCountries} from "../redux/userActions";

const OneTableRow = ({c, onDownloadZip, id, onDeleteCountry, language}) => {
    const [isConfirm, setIsConfirm] = useState(false)
    const onToggle = (val) => setIsConfirm(val)
    const text = languages[language]
    const cls = ["toast position-absolute"]
    if (isConfirm) {
        cls.push("show d-block")
    } else {
        cls.push("d-none")
    }
    const onConfirmDelete = () => {
        onDeleteCountry(c._id)
        onToggle(false)
    }
    return (
        <tr key={c._id}>
            <th scope="row">{id + 1}</th>
            <td>{c.name}</td>
            <td>{c.results.length}</td>
            {c.results.length ? <td className={"position-relative"}>
                <button onClick={onDownloadZip.bind(null, c._id)} className={"btn me-3 bg-dark-green text-light"}>
                    Download pdf
                </button>
                <button
                    onClick={onToggle.bind(null, true)}
                    className={"btn btn-danger"}>
                    Reset
                </button>
                {c.toExcel.length && <a rel={"noreferrer"} href={`${serverURL}/toexcel?countryId=${c._id}`}
                                        onClick={getAxiosCountries}
                                        target={"_blank"}
                                        className={"btn bg-dark-green text-light ms-3 border border-dark"}>Export
                    to excel</a>}
                <div data-autohide="true"
                     className={cls.join(" ")}
                     style={{background: "#fff", top: 0, zIndex: 100}}>
                    <div className="toast-header">
                        <span className={"bg-danger rounded me-2"} style={{width: 20, height: 20}}/>
                        <strong className="me-auto">

                            {text.admin.delQuestion}

                        </strong>
                    </div>
                    <div className="toast-body d-flex justify-content-between">
                        <button onClick={onConfirmDelete} className={"btn btn-danger"}>Delete</button>
                        <button onClick={onToggle.bind(null, false)} className={"btn btn-primary"}>Cancel</button>
                    </div>
                </div>
            </td> : <td/>}
        </tr>
    );
};

export default OneTableRow;