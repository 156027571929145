import {
    SET_NEW_PASS,
    SET_USER,
    SET_PDF_LINK,
    SET_LANGUAGE,
    SET_COUNTRIES,
    SET_DOWNLOAD_LINK,
    RUS,
    GRUZIAN, GERMANY, SPANISH,
    SET_MAIL_ISLOAD, SUCCESS_EMAIL_SEND, ENGLISH, MONGOL, KENIROANDA
} from "./userTypes";
import {saveUserInLocalStorage} from "../../assets/helpers";

export const setPdfLink = (link) => {
    return {
        type: SET_PDF_LINK,
        payload: {link}
    }
}
export const onToggleLoader = () => ({type: SET_MAIL_ISLOAD})
export const setCountries = (countries) => {
    return {
        type: SET_COUNTRIES,
        payload: {countries}
    }
}

export const setDownloadLink = (link) => {
    return {
        type: SET_DOWNLOAD_LINK,
        payload: {link}
    }
}
export const setActiveLang = (language) => {
    return {
        type: SET_LANGUAGE,
        payload: {language}
    }
}
export const setUserAction = (user) => {
    return {
        type: SET_USER,
        payload: {
            user
        }
    }
}
export const setNewPass = (password) => {
    return {
        type: SET_NEW_PASS,
        payload: {
            password
        }
    }
}
export const sendPdfOnMail = ({filepath, text, email, toastRef}, isEmail, language) => {
    return async (dispatch, _, axios) => {
        try {
            dispatch(onToggleLoader())
            await axios.post("/sendmail", {filepath, text, email, onShareTxt: language})
            if (isEmail) return dispatch({type: SUCCESS_EMAIL_SEND})
            toastRef.current.classList.remove("d-none")
            toastRef.current.classList.add("show")
            setTimeout(() => {
                dispatch(onToggleLoader())
                toastRef.current.classList.remove("show")
                toastRef.current.classList.add("d-none")
            }, 10000)
        } catch (e) {
            dispatch(onToggleLoader())
        }
    }
}
export const getZipFileByCountry = (id) => {
    return async (dispatch, _, axios) => {
        const response = await axios.get(`/create/zip/${id}`)
        dispatch(setDownloadLink(response.data))
    }
}

export const axiosResetCountry = (id) => {
    return async (dispatch, _, axios) => {
        try {
            await axios.delete(`/countries/${id}`)
            dispatch(getAxiosCountries())
        } catch (e) {

        }
    }
}
export const getAxiosCountries = () => {
    return async (dispatch, _, axios) => {
        try {
            const response = await axios.get("/countries")
            dispatch(setCountries(response.data))
        } catch (e) {

        }
    }
}
export const loginUser = (data, country) => {
    return async (dispatch, _) => {
        try {
            const newUser = {
                email: data.email,
                firstName: data.firstName,
                lastName: data.lastName,
                role: "client",
                countryId: data.countryId
            }
            if (country.name === "Kyrgyzstan") {
                dispatch(setActiveLang(RUS))
            } else if (country.name === "Georgia") {
                dispatch(setActiveLang(GRUZIAN))
            } else if (country.name === "Mongolia") {
                dispatch(setActiveLang(MONGOL))
            } else if (country.name === "Switzerland") {
                dispatch(setActiveLang(GERMANY))
            } else if (country.name === "Nicaragua") {
                dispatch(setActiveLang(SPANISH))
            } else if (country.name === "Rwanda ENGL") {
                dispatch(setActiveLang(ENGLISH))
            } else if (country.name === "Rwanda KeRW") {
                dispatch(setActiveLang(KENIROANDA))
            }
            saveUserInLocalStorage(newUser)
            dispatch(setUserAction(newUser))
        } catch {

        }
    }
}

export const logoutUser = () => {
    return async (dispatch, _) => {
        try {
            localStorage.removeItem("user")
            dispatch(setUserAction(null))
        } catch {
            localStorage.removeItem("user")
            dispatch(setUserAction(null))
        }
    }
}

export const axiosGenerateResult = (data) => {
    return async (dispatch, getState, axios) => {
        try {
            const userState = getState().user
            const user = userState.user
            const response = await axios.post("/generatepdf", {
                ...data,
                ...user,
            })
            dispatch(setPdfLink(response.data))
        } catch (e) {
            console.log(e)
        }
    }
}
