import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import "bootstrap/dist/js/bootstrap.bundle.min"
import {BrowserRouter} from "react-router-dom";
import App from "./App";
import {Provider} from "react-redux";
import store from "./components/redux/store";


ReactDOM.render(
    <React.StrictMode>
        <BrowserRouter>
            <Provider store={store}>
                <App/>
            </Provider>
        </BrowserRouter>
    </React.StrictMode>,
    document.getElementById("root")
);

// "backURI": "http://65.21.255.14:3003",
//     "appURL": "http://65.21.255.14"
