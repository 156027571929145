import React from "react";
import {Link} from "react-router-dom";
import texts from "../../assets/languages";
import {useSelector} from "react-redux";
import {getActiveLanguage} from "../redux/getters";

const QuestionsInfoPage = () => {
    const language = useSelector(getActiveLanguage)
    const text = texts[language].qInfo
    return (
        <div className={"container"}>
            <p className={"text-light-weight text-size"}>
                {text.first}
            </p>
            <p className={"text-light-weight text-size"}>
                {text.second}
            </p>
            <ul className={"text-light-weight text-size mt-5"}>
                <li>
                    {text.threeOne}
                </li>
                <li>                    {text.threeTwo}
                </li>
                <li>                    {text.threeThree}
                </li>
                <li>                    {text.threeFour}
                </li>
            </ul>
            <p className={"text-light-weight text-size"}>
                {text.fourty}
            </p>
            <p className={"text-light-weight text-size"}>
                {text.fifth}
            </p>
            <div className={"d-flex justify-content-between flex-wrap align-items-center"}>
                <Link to={"/questions/1"} className={"btn border-dark btn-primary me-3 mb-3 btn-lg bg-dark-green"}>{text.inAnketBtn}</Link>
                <Link to={"/"} className={"btn btn-outline-dark border-dark btn-lg bg-gray"}>{text.inBackBtn}</Link>
            </div>
        </div>
    );
};

export default QuestionsInfoPage;
