import React from "react";
import SubQuestion from "../QuestionsPage/SubQuestion";

const SubQuestions = ({questions, selectedAnswers, onSelectAnswer, questionId,language}) => {

    return (
        <ul className="list-group text-light-weight">
            {
                questions.map((question) => <SubQuestion key={question.id}
                                                         language={language}
                                                         questionId={questionId}
                                                         subQuestionId={question.id}
                                                         answers={selectedAnswers}
                                                         onSelectActiveAnswer={onSelectAnswer}
                                                         question={question}/>)
            }
        </ul>
    );
};

export default SubQuestions;
