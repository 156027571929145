export const getUserFromLocalStorage = () => {
    try {
        const serializedState = localStorage.getItem("user");
        return JSON.parse(serializedState || "null");
    } catch {
        //no user
    }
};
export const saveUserInLocalStorage = user => {
    try {
        const serializedState = JSON.stringify(user);
        localStorage.setItem("user", serializedState);
    } catch {
        // error when save in local
    }
};

export const createMultiFormData = (data) => {
    const multiPostData = new FormData()
    Object.keys(data).forEach((name) => {
        if (name === "files") {
            Object.values(data[name]).forEach(file => {
                multiPostData.append(name, file)
            })
            return
        }
        multiPostData.append(name, data[name])
    })
    return multiPostData
}
