import {
    SET_COUNTRIES,
    SET_DOWNLOAD_LINK,
    SET_LANGUAGE,
    SET_MAIL_ISLOAD,
    SET_NEW_PASS,
    SET_USER,
    SUCCESS_EMAIL_SEND
} from "./userTypes";

const initialState = {
    user: null,
    isLoading: null,
    errors: null,
    language: "rus",
    countries: [],
    link: null,
    mailMessage: null
}
const userReducer = (state = initialState, action) => {
    const {type, payload} = action
    switch (type) {
        case SUCCESS_EMAIL_SEND:
            return {...state, mailMessage: true}
        case SET_MAIL_ISLOAD:
            return {...state, isLoading: !state.isLoading}
        case SET_USER:
            return {...state, user: payload.user}
        case SET_NEW_PASS:
            return {...state, password: payload.password}
        case SET_LANGUAGE:
            return {...state, language: payload.language}
        case SET_DOWNLOAD_LINK:
            return {...state, link: payload.link}
        case SET_COUNTRIES:
            return {...state, countries: payload.countries}
        default:
            return state
    }
}

export default userReducer
