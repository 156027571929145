import React, {useEffect, useState} from "react";
import {Link, useHistory, useParams} from "react-router-dom"
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {clearQuestionState, selectQuestionAnswer, setActiveQuestion} from "../QuestionsPage/questionActions";
import SubQuestions from "../SubQuestion/SubQuestion";
import {getActiveLanguage} from "../redux/getters";
import texts from "../../assets/languages";

const QuestionPage = () => {
    const {questionId} = useParams()
    const history = useHistory()
    const [messageIsShow, setMessageIsShow] = useState(null)
    const {activeQuestion, questions} = useSelector(state => state.questions, shallowEqual)
    const language = useSelector(getActiveLanguage)
    const text = texts[language].question
    const dispatch = useDispatch()
    const activeQuestionSubQuestions = text[parseInt(questionId)]
    useEffect(() => {
        try {
            setMessageIsShow(false)
            dispatch(setActiveQuestion(parseInt(questionId)))
        } catch {
        }
    }, [questionId, dispatch])
    const selectQuestionAnswerHandler = (questionId, subQuestionId, answerId) =>
        dispatch(selectQuestionAnswer(questionId, subQuestionId, answerId))
    const onQuestionsStateClear = () => dispatch(clearQuestionState())
    const isFirst = activeQuestion ? activeQuestion.id === 1 : false
    const isLast = activeQuestion ? questions.length === activeQuestion.id : false
    const onNextQuestion = () => {
        const answered = activeQuestion.selectedAnswers.filter(q => q.questionId === null)
        if (answered.length) {
            setMessageIsShow(true)
            setTimeout(() => {
                setMessageIsShow(false)
            }, 3600)
        } else {
            if (isLast) {
                return history.push("/result")
            }
            history.push(`/questions/${activeQuestion.id + 1}`)
        }
    }
    return (
        <div className={"container mt-3"}>
            <p className={"text-size"}>{text.qInfo1} {activeQuestion && activeQuestion.id} {text.qInfo2}</p>
            {messageIsShow && <div className="alert alert-danger" role="alert">
                {text.badMessageTxT}
            </div>}
            {
                activeQuestion &&
                <SubQuestions questionId={activeQuestion.id}
                              language={language}
                              onSelectAnswer={selectQuestionAnswerHandler}
                              selectedAnswers={activeQuestion.selectedAnswers}
                              questions={activeQuestionSubQuestions}/>
            }
            {
                activeQuestion && <div className={"d-flex justify-content-between flex-wrap align-items-start mt-4"}>

                    <div className={"d-flex flex-column"}>
                        {!isFirst && <Link className={"btn border-dark text-light btn-lg mb-3 bg-dark-green"}
                                           to={`/questions/${activeQuestion.id - 1}`}>{text.inBackBtn}</Link>}
                        <Link to={"/"}
                              onClick={onQuestionsStateClear}
                              className={"btn border-dark btn-outline-dark btn-lg bg-gray"}>{text.inStartBtn}
                        </Link>
                    </div>
                    <button
                        onClick={onNextQuestion}
                        className={"btn border-dark text-light btn-lg bg-dark-green"}>
                        {text.inNextBtn}
                    </button>
                </div>
            }
        </div>
    );
}

export default QuestionPage
