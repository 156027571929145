import {CLEAR_QUESTIONS_STATE, SELECT_QUESTION_ANSWER, SET_ACTIVE_QUESTION, SET_RESULT} from "./questionActions";
import {SET_PDF_LINK} from "../redux/userTypes";

const questionsState = {
    questions: [
        {
            id: 1,
            selectedAnswers: [{
                id: 1,
                questionId: null,
            },
                {
                    id: 2,
                    questionId: null,
                },
                {
                    id: 3,
                    questionId: null,
                },
                {
                    id: 4,
                    questionId: null,
                }],
        },
        {
            id: 2,
            selectedAnswers: [{
                id: 1,
                questionId: null,
            },
                {
                    id: 2,
                    questionId: null,
                },
                {
                    id: 3,
                    questionId: null,
                },
                {
                    id: 4,
                    questionId: null,
                }],
        },
        {
            id: 3,
            selectedAnswers: [{
                id: 1,
                questionId: null,
            },
                {
                    id: 2,
                    questionId: null,
                },
                {
                    id: 3,
                    questionId: null,
                },
                {
                    id: 4,
                    questionId: null,
                }],
        },
        {
            id: 4,
            selectedAnswers: [{
                id: 1,
                questionId: null,
            },
                {
                    id: 2,
                    questionId: null,
                },
                {
                    id: 3,
                    questionId: null,
                },
                {
                    id: 4,
                    questionId: null,
                }],
        },
        {
            id: 5,
            selectedAnswers: [{
                id: 1,
                questionId: null,
            },
                {
                    id: 2,
                    questionId: null,
                },
                {
                    id: 3,
                    questionId: null,
                },
                {
                    id: 4,
                    questionId: null,
                }],
        },
        {
            id: 6,
            selectedAnswers: [{
                id: 1,
                questionId: null,
            },
                {
                    id: 2,
                    questionId: null,
                },
                {
                    id: 3,
                    questionId: null,
                },
                {
                    id: 4,
                    questionId: null,
                }],
        },
        {
            id: 7,
            selectedAnswers: [{
                id: 1,
                questionId: null,
            },
                {
                    id: 2,
                    questionId: null,
                },
                {
                    id: 3,
                    questionId: null,
                },
                {
                    id: 4,
                    questionId: null,
                }],
        },
        {
            id: 8,
            selectedAnswers: [{
                id: 1,
                questionId: null,
            },
                {
                    id: 2,
                    questionId: null,
                },
                {
                    id: 3,
                    questionId: null,
                },
                {
                    id: 4,
                    questionId: null,
                }],
        },
        {
            id: 9,
            selectedAnswers: [{
                id: 1,
                questionId: null,
            },
                {
                    id: 2,
                    questionId: null,
                },
                {
                    id: 3,
                    questionId: null,
                },
                {
                    id: 4,
                    questionId: null,
                }],
        },
        {
            id: 10,
            selectedAnswers: [{
                id: 1,
                questionId: null,
            },
                {
                    id: 2,
                    questionId: null,
                },
                {
                    id: 3,
                    questionId: null,
                },
                {
                    id: 4,
                    questionId: null,
                }],
        },
        {
            id: 11,
            selectedAnswers: [{
                id: 1,
                questionId: null,
            },
                {
                    id: 2,
                    questionId: null,
                },
                {
                    id: 3,
                    questionId: null,
                },
                {
                    id: 4,
                    questionId: null,
                }],
        },
        {
            id: 12,
            selectedAnswers: [{
                id: 1,
                questionId: null,
            },
                {
                    id: 2,
                    questionId: null,
                },
                {
                    id: 3,
                    questionId: null,
                },
                {
                    id: 4,
                    questionId: null,
                }],
        },
        {
            id: 13,
            selectedAnswers: [{
                id: 1,
                questionId: null,
            },
                {
                    id: 2,
                    questionId: null,
                },
                {
                    id: 3,
                    questionId: null,
                },
                {
                    id: 4,
                    questionId: null,
                }],
        },
        {
            id: 14,
            selectedAnswers: [{
                id: 1,
                questionId: null,
            },
                {
                    id: 2,
                    questionId: null,
                },
                {
                    id: 3,
                    questionId: null,
                },
                {
                    id: 4,
                    questionId: null,
                }],
        },
        {
            id: 15,
            selectedAnswers: [{
                id: 1,
                questionId: null,
            },
                {
                    id: 2,
                    questionId: null,
                },
                {
                    id: 3,
                    questionId: null,
                },
                {
                    id: 4,
                    questionId: null,
                }],
        },
    ],
    activeQuestion: null,
    result: {
        beePercent: 0,
        monkeyPercent: 0,
        eaglePercent: 0,
        pandaPercent: 0,
        heights: {
            eagleHeight: 0,
            beeHeight: 0,
            monkeyHeight: 0,
            pandaHeight: 0
        }
    },
    pdfLink: null,
    isSended: false
}
export const questionsReducer = (state = questionsState, action) => {
    const {payload, type} = action
    switch (type) {
        case SET_ACTIVE_QUESTION:
            return {
                ...state,
                activeQuestion: state.questions.find(q => q.id === payload.id)
            }
        case SELECT_QUESTION_ANSWER:
            return {
                ...state,
                questions: state.questions.map(q => q.id === payload.activeQuestion.id ? payload.activeQuestion : q),
                activeQuestion: {
                    ...payload.activeQuestion
                }
            }
        case CLEAR_QUESTIONS_STATE:
            return JSON.parse(JSON.stringify(questionsState))
        case SET_RESULT:
            return {...state, result: payload.result, isSended: payload.isSended}
        case SET_PDF_LINK:
            return {...state, pdfLink: payload.link}
        default:
            return state
    }
}