export const SET_USER = "LOGIN_USER"
export const SET_NEW_PASS = "SET_NEW_PASS"
export const SET_PDF_LINK = "SET_PDF_LINK"
export const RUS = "rus"
export const GRUZIAN = "gruzian"
export const SPANISH = "spanish"
export const MONGOL = "mongol"
export const GERMANY = "german"
export const ENGLISH = "en"
export const KENIROANDA = "keni"
export const SET_LANGUAGE = "SET_LANGUAGE"
export const SET_COUNTRIES = "SET_COUNTRIES"
export const SET_DOWNLOAD_LINK = "SET_DOWNLOAD_LINK"
export const SET_MAIL_ISLOAD = "SET_MAIL_ISLOAD"
export const SUCCESS_EMAIL_SEND = "SUCCESS_EMAIL_SEND"
