import Header from "./components/Header/Header";
import {useDispatch} from "react-redux";
import Routes from "./containers/Routes";
import {setActiveLang} from "./components/redux/userActions";
import {ENGLISH, GERMANY, GRUZIAN, MONGOL, RUS, SPANISH,KENIROANDA} from "./components/redux/userTypes";
import {useEffect} from "react";

function App() {
    const host = window.location.host
    const dispatch = useDispatch()
    useEffect(() => {
        switch (host) {
            case "personality.bpngeorgia.ge":
                dispatch(setActiveLang(GRUZIAN))
                break
            case "personality.bpn.ch":
                dispatch(setActiveLang(GERMANY))
                break
            case "personality.bpnnicaragua.com":
            case "personalidad.bpnnicaragua.com":
                dispatch(setActiveLang(SPANISH))
                break;
            case "personality.bpn.mn":
                dispatch(setActiveLang(MONGOL))
                break;
            default:
                dispatch(setActiveLang(RUS))
                break
        }
    }, [])

    return (
        <div className="App">
            <Header/>
            <Routes/>
        </div>
    );
}

export default App;
