import React from "react";
import {serverURL} from "../components/redux/store";
import {useSelector} from "react-redux";
import {getActiveLanguage} from "../components/redux/getters";
import texts from "../assets/languages";

const FourTendentions = () => {
    const language = useSelector(getActiveLanguage)
    const text = texts[language].fourTendentions
    return (
        <div className={"container "}>
            <h4 className={"text-medium-weight text-size"}>{text.title}</h4>
            <h5 className={"text-medium-weight text-size"}>{text.eagleTitle}</h5>
            <div className={"mx-auto w-75 my-3"}>
                <img src={`${serverURL}/eagle-info-${language}.jpg`}
                     className={"w-100"}
                     alt="eagle-info"/>
            </div>
            <p className={"text-light-weight text-italic bg-gray py-2 px-1"}>
                {text.eagleGrayTxt}</p>
            <p className={"text-light-weight"}>{text.eagleTxt}</p>
            <p className={"text-light-weight"}><b>{text.eagleSlogan1}</b>{text.eagleSlogan2}</p>
            <h5 className={"text-medium-weight text-size"}>{text.monkeyTitle}</h5>
            <div className={"mx-auto mb-3 w-75"}>
                <img src={`${serverURL}/monkey-info-${language}.jpg`}
                     className={"w-100"} alt="обезьяна"/>
            </div>
            <p className={"text-light-weight text-italic bg-gray py-2 px-1"}>{text.monkeyGrayTxt}</p>
            <p className={"text-light-weight"}>{text.monkeyTxt}</p>
            <p className={"text-light-weight"}><b>{text.monkeySlogan1}</b>{text.monkeySlogan2}</p>
            <h5>{text.pandaTitle}</h5>
            <div className={"mx-auto mb-3 w-75"}>
                <img src={`${serverURL}/panda-info-${language}.jpg`} className={"w-100"} alt="панда"/>
            </div>
            <p className={"text-light-weight text-italic bg-gray py-2 px-1"}>{text.pandaGrayTxt}</p>
            <p className={"text-light-weight"}>{text.pandaTxt}</p>
            <p className={"text-light-weight"}><b>{text.pandaSlogan1}</b>{text.pandaSlogan2}
            </p>
            <h5 className={"text-medium-weight text-size"}>{text.beeTitle}</h5>
            <div className={"mx-auto mb-3 w-75"}>
                <img src={`${serverURL}/bee-info-${language}.jpg`} className={"w-100"} alt="пчела"/>
            </div>
            <p className={"text-light-weight text-italic bg-gray py-2 px-1"}>{text.beeGrayTxt}</p>
            <p className={"text-light-weight"}>{text.beeTxt}
            </p>
            <p className={"text-light-weight"}><b>{text.beeSlogan1}</b>{text.beeSlogan2}</p>
        </div>
    );
};

export default FourTendentions;
