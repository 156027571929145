import React from "react";
import languages from "../../assets/languages";

const SubQuestion = ({question, onSelectActiveAnswer, answers, subQuestionId, questionId, language}) => {
    const isEven = question.id % 2 === 0
    const questionsVars = ["a", "b", "c", "d"]
    const text = languages[language].question
    let questionPlaceholders = (i) => null
    if (question.id === 1) {
        questionPlaceholders = (i) => {
            return <div className={"q-info-placeholder border-1 border-dark border bg-light-green"}>
                <span>{text[`${i}Punkt`]}</span>
            </div>
        }
    }
    const classes = ["list-group-item d-flex align-items-center justify-content-between p-0 ps-2"]
    if (isEven) {
        classes.push("bg-light-green")
    }
    const subQuestionHasAnswer = answers.filter(answer => answer.questionId === subQuestionId)[0]
    return (
        <li
            className={classes.join(" ")}>
            <span>{questionsVars[question.id - 1]}. {question.title}</span>
            <div className={"d-flex"}>
                {
                    answers.map((answer) => {
                        const btnParentClasses = ["p-3 position-relative border-dark"]
                        const btnClasses = ["btn border border-green border-2 btn-disabled"]
                        if (answer.id === 1) {
                            btnParentClasses.push("border-end border-start")
                        } else {
                            btnParentClasses.push("border-end")
                        }
                        if (answer.questionId === subQuestionId) {
                            btnClasses.push("bg-dark-green text-light")
                        } else {
                            btnClasses.push("btn-light")
                        }
                        const isDisabled = answer.questionId && subQuestionId !== answer.questionId || subQuestionHasAnswer
                            && subQuestionHasAnswer.questionId === subQuestionId && subQuestionHasAnswer.id !== answer.id
                        return <div key={answer.id} className={btnParentClasses.join(" ")}>
                            {questionPlaceholders(answer.id)}
                            <button
                                disabled={isDisabled}
                                onClick={() => onSelectActiveAnswer(questionId, subQuestionId, answer.id)}
                                className={btnClasses.join(" ")}>{answer.id}</button>
                        </div>
                    })
                }
            </div>
        </li>
    );
};

export default SubQuestion;
